:root
{
  --primary: white;
  --secondary: #1ad254;
}

.navbar {
  height: 50px;
  top: 0px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 100;
  padding-right: 1vw;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary);
}

.navbar a {
  color: var(--primary);
  text-decoration: none;
  margin: 20px;
  text-transform: uppercase;
  font-size: 17px;
}

.navbar a:hover
{
  color: var(--secondary);
  transition: color 0.25s ease-out;
}

.navbar a.active
{
  color: var(--secondary);
  font-weight: 600;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar#open
{
  background: rgb(20,20,20);
	background: -moz-linear-gradient(90deg, rgba(20,20,20,.5) 0%, rgba(55,55,55,.5) 100%);
	background: -webkit-linear-gradient(90deg, rgba(20,20,20,.5) 0%, rgba(55,55,55,.5) 100%);
	background: linear-gradient(90deg, rgba(20,20,20,.5) 0%, rgba(55,55,55,.5) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#373737",GradientType=1);
  backdrop-filter: blur(15px);
  height: 100vh;
  border-bottom: 3px solid rgba(10,10,10,1);
}

#open .links {
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 200px);
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}