:root
{
  --promptSpecialColour: #1ad254;
}

.home {
  width: 100%;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.about {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  color: #f0f0f0;
  
  background: rgb(20,20,20);
  background: -moz-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
  background: linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#373737",GradientType=1);
}

.about h1 {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 64px;
  color: white;
  text-wrap: nowrap;
  width: min-content;
  margin: 6px;
}

.about h1:last-of-type
{
  margin-bottom: 50px;
}

#portraitBorder
{
  height: 25vw;
  width: 25vw;
  position: relative;
  background: rgb(24,24,24);
  background: -moz-linear-gradient(0deg, rgba(24,24,24,0.6979166666666667) 0%, rgba(68,68,68,0.4009978991596639) 100%);
  background: -webkit-linear-gradient(0deg, rgba(24,24,24,0.6979166666666667) 0%, rgba(68,68,68,0.4009978991596639) 100%);
  background: linear-gradient(0deg, rgba(24,24,24,0.6979166666666667) 0%, rgba(68,68,68,0.4009978991596639) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#181818",endColorstr="#444444",GradientType=1);
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.5);
}

#portraitImg
{
  position: absolute;
  width: 25vw;
  aspect-ratio: 3 / 4;
  bottom: 0;
  left: 0;
  filter: drop-shadow(11px 0px 10px rgba(0, 0, 0, 0.5));
}

.about .prompt {
  width: 40%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1280px)
{
  #portraitBorder, #portraitImg
  {
    display: none;
  }

  .about h1
  {
    font-size: 7vw;
    text-align: center;
  }
}

.prompt #promptSpecialText
{
  color: var(--promptSpecialColour);
  padding-bottom: 4px;
  position: relative;
  cursor: pointer;
}

@media only screen and (min-width: 800px)
{
  
  .prompt #promptSpecialText:hover::after
  {
    width: 100%;
    transition: width .5s ease-in-out;
  }

  .prompt #promptSpecialText::after
  {
    position: absolute;
    bottom: -2px;
    left: 0px;
    height: 2px;
    width: 0px;
    background-color: var(--promptSpecialColour);
    display: block;
    content: '';
    transition: width .4s ease-in-out;
  }

}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1 {
  color: white;
}

.skills .list {
  list-style: none;
  width: 60%;
  padding: 0;
}

.skills .list h2
{
  margin: 15px;
  margin-top: 6px;
  text-align: left;
  font-size: 32px;
}

.skills .list li
{
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  margin: 18px 0px;
  padding: 16px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15) , rgba(255, 255, 255, 0.2));
}

.list span {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}