:root
{
	--timeline-background-color: rgba(255, 255, 255, 0.3);
	--secondary-color: #1ad254;
}

.experience
{
	background: rgb(20,20,20);
	background: -moz-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	background: linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#373737",GradientType=1);
	color: white;
	padding-top: 50px;
	padding-bottom: 100px;
}

.experience #experienceTitle
{
	font-size: 40px;
	padding-bottom: 1.25rem;
}

.experience .vertical-timeline-element-content
{
	background: var(--timeline-background-color);
	backdrop-filter: blur(10px);
	box-shadow: 5px 6px 10px rgba(0, 0, 0, 0.5);
}

.experience .vertical-timeline-element-content-arrow
{
	border-right-color: var(--timeline-background-color);
}

.experience h4.vertical-timeline-element-subtitle
{
	font-weight: 600;
	color: rgb(221, 221, 221);
}

.experience .vertical-timeline-element-subtitle a
{
	color: rgb(214, 214, 214);
	text-decoration: none;
}