.about-page
{
	color: white;
	height: 100vh;
}

#title
{
	margin: 100px 10px 10px 10px;
	font-size: 40px;
	padding-bottom: 1.25rem;
}

.container-about
{
	margin: auto 10%;
}

h4.aboutHeading
{
	font-size: 2.5rem;
}

p.aboutParagraph
{
	font-size: 1.6rem;
}