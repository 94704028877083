:root
{
  --primary: white;
  --secondary: #1ad254;
}

.App {
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  background: rgb(20,20,20);
	background: -moz-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	background: linear-gradient(90deg, rgba(20,20,20,1) 0%, rgba(55,55,55,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#141414",endColorstr="#373737",GradientType=1);
}

body
{
  padding: 0px;
  margin: 0px;
}