.label
{
	font-size: 0.8rem;
	color: black;
	background-color: rgba(255, 255, 255, 0.8);
	width: min-content;
	padding: 6px 14px;
	border-radius: 50px;
	margin: -8px 4px;
	margin-bottom: 12px;
	display: inline-block;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}