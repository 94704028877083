.projects {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.projects .projectTitle {
  text-align: center;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.projectList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* MENU ITEM STYLING */

.projectItem {
  border-radius: 15px;
  width: 300px;
  height: 300px;
  margin: 40px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.projectItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in;
  cursor: pointer;
  outline: 2px solid var(--secondary);
}

.projectItem .bgImage {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItem h2 {
  font-size: 24px;
  padding: 0px 10px;
}

.projectItem.disabled
{
  box-shadow: none;
}

.projectItem.disabled .bgImage
{
  filter: grayscale(1);
}

.projectItem.disabled .bgImage::after
{
  content: 'COMING SOON';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5%;
  font-size: 1.25rem;
}

.projectItem.disabled:hover
{
  box-shadow: none;
  cursor: default;
  outline: none;
}

@media only screen and (max-width: 1300px) {
  .projectList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .projectList {
    width: 100%;
  }
  .projectItem {
    width: 300px;
    height: 300px;
  }
}