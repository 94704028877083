.nextPageBanner
{
	margin-top: 2rem;
	padding: 2.5rem 6px;
	background-color: rgba(0, 0, 0, 0.3);
	transition: .25s all;
}

.nextPageBanner:hover
{
	box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.8);
	transition: .25s all;
	cursor: pointer;
}

.nextPageBanner:hover .nextPageBtn, .nextPageBtn:hover
{
	color: var(--secondary);
	transition: .25s all;
}

.nextPageBtn
{
	background-color: transparent;
	color: white;
	border: none;
	cursor: pointer;
	transition: .25s color;
	text-transform: uppercase;
	font-size: 2.25rem;
	font-weight: 800;
}