.contact
{
	color: white;
	height: 100vh;
}

#title
{
	margin: 100px 10px 10px 10px;
	font-size: 40px;
	padding-bottom: 1.25rem;
}

.contact-container
{
	display: flex;
	gap: 2.5rem;
	justify-content: center;
	flex-wrap: wrap;
}

.contact-container .contact-icons
{
	width: 40%;
	height: 40%;
}

.contact-container .contact-option
{
	border: 1px solid white;
	border-radius: 6px;
	padding: 2rem;
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.3);
	width: 400px;
	max-width: 400px;
}

.contact-container h4
{
	font-weight: 500;
	font-size: 1.25rem;
}

.contact-container h4 a
{
	text-decoration: none;
	color: white;
}

.contact-container h4 a:hover
{
	text-decoration: underline;
	color: white;
}

@media screen and (max-width: 975px)
{
	.contact-container
	{
		flex-wrap: wrap;
		margin: 25px;
	}

	.contact-container .contact-option
	{
		flex: 1;
		min-width: 200px;
	}
}